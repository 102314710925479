import axios from 'axios';
import { getRootPath } from '../utils/helper';

export const messagesController = async (smsData, endpoint, method, callback) => {
  try {
    const token = localStorage.getItem('x-access-token');
    const apiEndPoint = endpoint ?? '';

    const headers = {
      'x-access-token': token,
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    const response = await axios({
      url: `${getRootPath()}sms${apiEndPoint}`,
      method: method,
      headers: headers,
      data: smsData
    });

    if (response.status === 200) {
      callback(response.data);
    } else {
      console.log('Error performing patient request', response.data);
      callback(false);
    }
  } catch (error) {
    console.log('Unexpected error', error);
    callback(false);
  }
};

export const sendMMS = async (smsData, endpoint, callback) => {
  try {
    const token = localStorage.getItem('x-access-token');
    const apiEndPoint = endpoint ?? '';

    const headers = {
      'x-access-token': token
    };

    const response = await axios({
      url: `${getRootPath()}sms${apiEndPoint}`,
      method: 'POST',
      headers: headers,
      data: smsData // Directly use FormData without converting to JSON
    });

    if (response.status === 200) {
      callback(response.data);
    } else {
      console.log('Error performing patient request', response.data);
      callback(false);
    }
  } catch (error) {
    console.log('Unexpected error', error);
    callback(false);
  }
};
