import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import ChatBox from "./chat/ChatBox";
import InputMask from "react-input-mask";
import "./App.css";
import axios from "axios";

let mockPatient = {
  _id: "adsfas23cc",
  patientId: "123456",
  patientName: "Private Message",
  phoneNumber: "555-555-1234",
  didConsent: false,
};

async function loginPrivateLink(uniqueID) {
  try {
    const apiUrl = "https://api.zelohub.com/api/pl_login";
    const response = await axios.post(apiUrl, { loginToken: uniqueID });
    console.log(response);
    if (response?.data && response.data.token) {
      // Save token to localStorage
      localStorage.setItem("x-access-token", response.data.token);
      localStorage.setItem("pid", response.data.pid);
      mockPatient.patientId = response.data.pid;
      // Set axios default headers
      axios.defaults.headers.common["x-access-token"] = response.data.token;
      axios.defaults.headers.common["Accept"] = "application/json";
      return true;
    } else {
      console.error("Error Logging In");
      return false;
    }
  } catch (error) {
    console.error("Couldn't login with the provided unique ID:", error);
    return false;
  }
}

function PrivateRoute() {
  const { uniqueID } = useParams();
  const [activeUser, setActiveUser] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [messageText, setMessageText] = useState(null);
  const [DOB, setDOB] = useState("");
  const [isDOBVerified, setDOBVerified] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const user = await loginPrivateLink(uniqueID);
      console.log("user value", user);
      if (user === null) {
        setErrorMessage(
          "The message you are trying to access has expired or is no longer valid"
        );
      } else {
        setActiveUser(mockPatient);
        setMessageText(user.message);
      }
    }

    fetchData();
  }, [uniqueID]);

  const handleDOBVerification = async () => {
    try {
      var token = localStorage.getItem("x-access-token");

      const apiUrl = "https://api.zelohub.com/api/pl/" + uniqueID;
      const response = await axios.get(apiUrl, {
        headers: {
          DOB: DOB,
        },
      });

      if (response?.data?.message || response?.data?.mediaPath) {
        setActiveUser(mockPatient);
        setMessageText(response.data);
        setDOBVerified(true);
      } else if (response?.data?.error) {
        setErrorMessage(response.data.error);
      } else {
        setErrorMessage("Verification failed.");
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data);
      } else {
        setErrorMessage(
          "The message you are trying to access has expired or is no longer valid"
        );
      }
    }
  };

  return (
    <div>
      {isDOBVerified ? (
        activeUser ? (
          <ChatBox activeUser={activeUser} bindMessage={messageText} />
        ) : (
          <div className="error-message">{errorMessage || "Loading..."}</div>
        )
      ) : (
        <div className="dob-container">
          <p className="dob-title">Enter your date of birth to continue.</p>
          <p className="dob-format">(DD-MM-YYYY)</p>
          <InputMask
            mask="99-99-9999"
            value={DOB}
            onChange={(e) => setDOB(e.target.value)}
          >
            {(inputProps) => (
              <input {...inputProps} type="text" className="dob-input" />
            )}
          </InputMask>
          <button
            className="verify-button"
            disabled={!DOB || !DOB.match(/^(\d{2})-(\d{2})-(\d{4})$/)}
            onClick={handleDOBVerification}
          >
            Verify
          </button>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
        </div>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ChatBox activeUser={mockPatient} />} />
        <Route path="/private/:uniqueID" element={<PrivateRoute />} />
      </Routes>
    </Router>
  );
}

export default App;
