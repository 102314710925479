import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import AudiotrackIcon from '@mui/icons-material/Audiotrack'; 
import MovieIcon from '@mui/icons-material/Movie'; 


const MMSFile = ({ fileUrl, fileType, token }: { fileUrl: string, fileType: string, token: string }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!fileUrl || !fileType || !token) {
      console.error("fileUrl, fileType, or token is missing", { fileUrl, fileType, token });
      setLoading(false);
      return;
    }

    if (fileType?.startsWith('image')) {
      fetch(fileUrl, {
        headers: { 'x-access-token': token }
      })
        .then(response => response.blob())
        .catch(error => console.error('Error fetching image', error))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);  // Handle non-image file types
    }
  }, [fileUrl, fileType, token]);

  if (loading) {
    return <div><CircularProgress /> Loading File...</div>;
  }

  if (fileType?.startsWith('image')) {
    return <img src={fileUrl} alt="MMS Image" style={{ maxWidth: '80%', borderRadius: '8px' }} />;
  }

  if (fileType?.startsWith('audio')) {  // New block for audio files
    return (
      <div>
        <AudiotrackIcon /> Play Audio
        <audio controls>
          <source src={fileUrl} type={fileType} />
          Your browser does not support the audio element.
        </audio>
      </div>
    );
  }

  if (fileType === 'application/pdf') {
    return <div><a href={fileUrl} target="_blank" rel="noopener noreferrer"><InsertDriveFileIcon /> View PDF</a></div>;
  }

  if (fileType?.startsWith('video')) {  // New block for video files
    return (
      <div>
        <MovieIcon /> Play Video
        <video controls width="280">
          <source src={fileUrl} type={fileType} />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  return ( <div>
    <InsertDriveFileIcon />
    <div>
      <a href={fileUrl} download style={{ textDecoration: 'underline', color: 'blue' }}>
        Download File
      </a>
    </div>
  </div>
);
};

export default MMSFile;
