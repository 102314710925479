import React, { useEffect, useRef, useState } from "react";
import {
  Widget,
  toggleWidget,
  isWidgetOpened,
  setQuickButtons,
  addUserMessage,
  addResponseMessage,
  renderCustomComponent,
  deleteMessages,
} from "react-chat-widget"; // Import the chat widget
import "react-chat-widget/lib/styles.css";
import "./ChatBox.css";
import { messagesController, sendMMS } from "../controllers/messagesController";
import MMSFile from "./MMSFile";
import { Patient } from "../interfaces/Patient";
import { useParams } from "react-router-dom";

const logo = require("../assets/ortho-logo.png");
interface ChatBoxProps {
  activeUser: Patient;
  bindMessage: string;
}

const ChatBox: React.FC<ChatBoxProps> = ({
  activeUser,
  bindMessage,
}: ChatBoxProps) => {
  const { uniqueLink } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setQuickButtons([{ label: "📎 File", value: "Attach-File" }]); // , {label: "💌 Send Private", value: "Send Private"}]);
    if (!isWidgetOpened()) {
      toggleWidget();
    }
    renderMessages([bindMessage]);
    const sendButtons = document.getElementsByClassName("rcw-send");
    if (sendButtons.length > 0) {
      sendButtons[0].addEventListener("submit", function (e) {
        e.preventDefault();
        console.log("Hello2");
      });
    }
  }, [bindMessage]);

  useEffect(() => {
    // Get the div that you want to scroll
    const messagesDiv = document.getElementById("messages");

    // If the div exists, scroll it to the bottom
    if (messagesDiv) {
      messagesDiv.scrollTop = messagesDiv.scrollHeight;
    }
  }, [messages]);

  const formatDate = (inputDate: string | Date) => {
    const date = new Date(inputDate);

    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const day = date.getDate().toString().padStart(2, "0"); // add leading zero if needed
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0"); // add leading zero if needed

    const amPm = hours < 12 ? "AM" : "PM";
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12;

    return `${month} ${day}, ${year}, ${hours}:${minutes} ${amPm}`; // Aug 13, 2023, 5:56 PM
  };
  const renderMessages = (messages: any[]) => {
    messages?.forEach((message) => {
      console.log("message =>", message);
      let messageText = `${message?.message}\n\n${formatDate(
        message?.sentDate
      )}`;
      // Check if the message is from the user or a response
      if (message?.isMMS === false) {
        if (message?.from === activeUser.phoneNumber) {
          addResponseMessage(messageText, message?._id);
        } else {
          messageText = `${messageText} \n ${message?.name}`;
          addUserMessage(messageText, message?._id);
        }
      } else {
        const token = localStorage.getItem("jwttoken");
        renderCustomComponent(
          MMSFile,
          { fileUrl: message?.mediaPath, fileType: message?.filetype, token },
          true
        );
      }
    });
    // Get the div that you want to scroll
    const messagesDiv = document.getElementById("messages");
    // If the div exists, scroll it to the bottom
    if (messagesDiv) {
      setTimeout(() => {
        messagesDiv.scrollTop = messagesDiv.scrollHeight;
      }, 1000);
    }
  };

  useEffect(() => {
    setIsLoading(true);

    // Define endpoint and method for fetching messages
    const endpoint = "/self-messages";
    const method = "GET";

    // Use messagesController to fetch messages
    messagesController(null, endpoint, method, (response: any) => {
      if (response) {
        // Assume the response contains an array of messages

        setMessages(response);
        //render messages
        deleteMessages(500);
        renderMessages(response);

        console.log("Messages fetched", response);
      } else {
        console.log("Error fetching messages");
      }

      setIsLoading(false);
    });
  }, []);

  const handleNewUserMessage = (newMessage: any) => {
    console.log(`New message outgoing! ${newMessage}`);

    messagesController(
      {
        message: newMessage,
        to: activeUser.phoneNumber,
        patient_id: activeUser._id,
        isMMS: false,
      },
      "/sendPatientSMS",
      "POST",
      (messageSent: any) => {
        if (messageSent) {
          //success
          console.log("SMS Sent");
        } else {
          // error sending.
          console.log(messageSent);
        }
      }
    );
  };
  const handleQuickButtonClicked = (e: any) => {
    const message = e;

    if (message === "Attach-File") {
      // Create an input element to select files
      const fileInput = document.createElement("input");
      fileInput.type = "file";

      // Add an event listener to handle the selected file
      fileInput.addEventListener("change", (event) => {
        const inputElement = event.target as HTMLInputElement;
        const selectedFile = inputElement.files?.[0];
        if (selectedFile) {
          const formData = new FormData();
          formData.append("file", selectedFile);
          // Assuming patientId is available in your scope, add it to the FormData
          formData.append("patient_id", activeUser._id);
          // You'll need to modify this endpoint according to your backend URL for file upload
          const endpoint = "/sendPatientMMS";
          const method = "POST";
          console.log("Selected File:", selectedFile);
          console.log("Active User ID:", activeUser._id);
          for (let [key, value] of formData.entries()) {
            console.log(key, value);
          }
          sendMMS(formData, endpoint, (response: { mediaPath: any }) => {
            if (response) {
              console.log("File uploaded successfully", response);
              const token = localStorage.getItem("jwttoken");
              renderCustomComponent(
                MMSFile,
                {
                  fileUrl: response.mediaPath,
                  fileType: selectedFile.type,
                  token,
                },
                true
              );
            } else {
              console.error("Error uploading file");
            }
          });
        }
      });

      // Trigger the file browser menu
      fileInput.click();
    }
  };
  return (
    <>
      <div className="logo-wrap">
        <img src={logo} alt="logo" />
      </div>
      <div className="chatbox-container">
        <div className="chatbox-header">
          <h1 style={{ fontSize: "17px", textAlign: "center" }}>
            {activeUser.patientName}
          </h1>
          {/* Chat Widget */}
          <div className="chat-widget-container">
            <Widget
              title=""
              subtitle=""
              handleNewUserMessage={(newMessage: any) =>
                handleNewUserMessage(newMessage)
              } // Handle incoming messages with messagesController
              handleQuickButtonClicked={(button: any) =>
                handleQuickButtonClicked(button)
              }
              showCloseButton={false}
              profileClientAvatar={logo}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBox;
